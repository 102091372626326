@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --skew-amount: -3deg;
    --magic-number: 0.09719; /* tan(11°)/2 */
    --content-width: 100vw;
    --skew-padding: calc(var(--content-width) * var(--magic-number));
}

.vignette {
    z-index: 50;
    pointer-events: none;
    box-shadow: 0 0 1.5vw 1.5vw #BAE6FD60 inset;
}

nav .active {
    @apply underline;
}

.diagonal-bottom {
    clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
}

.faq-text {
    transition-property: max-height;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
}

.hover\:accent-blur:hover {
    -webkit-box-shadow:0px 0px 25px 4px rgba(253,223,71,0.5);
    -moz-box-shadow: 0px 0px 25px 4px rgba(253,223,71,0.5);
    box-shadow: 0px 0px 25px 4px rgba(253,223,71,0.5);
}

section:target:before {
    content: '';
    display: block;
    position: relative;
    width: 0;
    height: 5em;
    margin-top: -5em
}

.diagonal-box {
    position: relative;

     & > div {
        margin: 0 auto;
        position: relative;
        padding-top: calc(var(--skew-padding)*0.25);
    }
}

html.no-js [data-aos] {
    opacity: 1;
    transform: none;
}